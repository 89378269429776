const reactQueryKeys = {
  organizationLogo: "organizationLogo",
  vendor: "vender",
  connection: "connection",
  users: "users",
  me: "me",
  logout: "logout",
  refreshToken: "refreshToken",
  organizationList: "organizationList",
  singleOrganization: "organization",
  locationList: "locationList",
  singleLocation: "singleLocation",
  singleUser: "singleRegularUser",
  userList: "userList",
  providerList: "providerList",
  adminProviderList: "adminProviderList",
  singleProvider: "singleProvider",
  clinicianList: "clinicianList",
  singleClinician: "singleClinician",
  myOrganization: "myOrganization",
  testMethodList: "testMethodList",
  singleTestMethod: "singleTestMethod",
  testList: "testList",
  singleTest: "singleTest",
  patientList: "patientList",
  singlePatient: "singlePatient",
  panelList: "panelList",
  singlePanel: "singlePanel",
  orderSetList: "orderSetList",
  singleOrderSet: "singleOrderSet",
  cptCodeList: "cptCodeList",
  singleCptCode: "singleCptCode",
  diagnosisCodeList: "diagnosisCodeList",
  singleDiagnosisCode: "singleDiagnosisCodeList",
  medicationList: "medicationList",
  singleMedication: "singleMedication",
  adminItems: "admin-items",
  insuranceList: "insuranceList",
  singleInsurance: "singleInsurance",
  singleTestReport: "singleTestReport",
  testReportList: "testReportList",
  singleAnatomicalTestReport: "singleAnatomicalTestReport",
  anatomicalTestReportList: "anatomicalTestReportList",
  clinicalOrderList: "clinicalOrderList",
  singleClinicalOrder: "singleClinicalOrder",
  testsForPatientsList: "testsForPatientsList",
  myReports: "myReports",
  testsForPatient: "testsForPatient",
  anatomicalPanelList: "anatomicalPanelList",
  anatomicalPane: "anatomicalPane",
  insurancePlanList: "insurancePlanList",
  insurancePlan: "insurancePlan",
  insuranceTypeList: "insuranceTypeList",
  insuranceType: "insuranceType",
  sampleTypeList: "sampleTypeList",
  sampleType: "sampleType",
  insuranceGroupList: "insuranceGroupList",
  insuranceGroup: "insuranceGroup",
  insuranceRuleTypeList: "insuranceRuleTypeList",
  insuranceRuleType: "insuranceRuleType",
  testMethodTypeList: "testMethodTypeList",
  testMethodType: "testMethodType",
  insuranceCompanyList: "insuranceCompanyList",
  singleInsuranceCompany: "singleInsuranceCompany",
  icd10CodeList: "icd10CodeList",
  singleIcd10Code: "singleIcd10Code",
  fileUrl: "fileUrl",
  commentLibrary: "commentLibrary",
  commentLibraryList: "commentLibraryList",
  history: "history",
  comboPanel: "comboPanel",
  diagnosisLibrary: "diagnosisLibrary",
  diagnosisLibraryList: "diagnosisLibraryList",
};

export default reactQueryKeys;
